.tru-faq-rich-text-block p {
  color: #00203f;
}

.tru-faq-rich-text-block li {
  color: #00203f;
  margin-bottom: 15px;
  font-size: 16px;
}

.tru-faq-rich-text-block h2,
.tru-faq-rich-text-block h4 {
  color: #00203f;
}

.tru-faq-rich-text-block h3 {
  color: #fff;
}

@media screen and (min-width: 480px) {
  .tru-faq-rich-text-block li {
    font-size: inherit;
  }
}

.faq-gradient-open {
  max-height: 2500px;
}

.faq-gradient-closed {
  max-height: 0px;
}
