.tru-inline-info {
  font-size: 0.9rem;
  color: #6abde0;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  font-family: Lato;
  padding: 0.75rem;
  border: 2px solid #6abde0;
  border-radius: 0.75rem;
  position: relative;
}

.tru-inline-info-icon {
  font-family: Montserrat;
  color: #fff;
  font-weight: 700;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #6abde0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: -12px;
  right: 12px;
  font-size: 1.1rem;
}
