.tru-about-modal-purple-bg {
  background: radial-gradient(circle at 50% 50%, #652c80 10%, transparent 50%);
}

.tru-hiring-bg {
  background: radial-gradient(circle at 50% 95%, #20568b 0%, transparent 22%);
}

.tru-team-section-bg-overlay {
  background-image: linear-gradient(#00203f, rgba(88, 46, 133, 0.8));
}

.tru-team-section-bg-image-overlay {
  background-image: linear-gradient(#00203f, rgba(0, 32, 63, 0));
}

.tru-account-settings-bg {
  background: radial-gradient(
      circle at 90% 20%,
      #652c80 0%,
      #00203f 50%,
      transparent 100%
    ),
    radial-gradient(
      circle at 0% 100%,
      #68e0cf 0%,
      #00203f 30%,
      transparent 100%
    );
}

.tru-accinfo-container-bg {
  background: linear-gradient(
      180deg,
      rgba(0, 62, 122, 0.5) 0%,
      rgba(0, 51, 102, 0.5) 100%
    ),
    rgba(32, 86, 139, 0.3);
}

.tru-single-modal-bg {
  background: radial-gradient(
    circle at 50% 40%,
    #20568b 0%,
    #00203f 80%,
    transparent 100%
  );
}

.tru-register-flow-purple-bg {
  background: radial-gradient(circle at 0% 0%, #652c80 0%, transparent 30%);
}

.tru-register-flow-blue-bg {
  background: radial-gradient(circle at 100% 0%, #68e0cf 0%, transparent 30%);
}

.tru-register-flow-middle-bg {
  background: radial-gradient(circle at 50% 280px, #20568b 0%, transparent 50%);
}

.tru-game-list-top-bg {
  background: radial-gradient(circle at 0% 20%, #652c80 0%, transparent 30%),
    radial-gradient(circle at 100% 55%, #38d58a 0%, transparent 20%);
}

.tru-game-list-blue-bg {
  background: radial-gradient(circle at 0% 78%, #68e0cf 0%, transparent 20%);
}

.tru-game-list-purple-bg {
  background: radial-gradient(circle at 100% 100%, #652c80 0%, transparent 30%);
}

.tru-free-content-purple-bg {
  background: radial-gradient(circle at 0% 40%, #652c80 0%, transparent 60%);
}

.tru-free-content-green-bg {
  background: radial-gradient(circle at 100% 90%, #38d58a 0%, transparent 30%);
}

.tru-free-content-blue-bg {
  background: radial-gradient(circle at 0% 100%, #68e0cf 0%, transparent 30%);
}

.tru-free-content-blue-bg2 {
  background: radial-gradient(circle at 50% 100%, #20568b 0%, transparent 30%);
}

.tru-free-content-modal-bg {
  background: radial-gradient(50% 50% at 50% 50%, #20568b 0%, #002952 83.54%);
}

/* Home bg gradients */

.tru-home-blue-bg1 {
  background: radial-gradient(circle at 100% 0%, #68e0cf 0%, transparent 50%);
}

.tru-home-green-bg1 {
  background: radial-gradient(circle at 100% 125%, #38d58a 0%, transparent 30%);
}

.tru-home-purple-bg1 {
  background: radial-gradient(circle at 0% 100%, #652c80 0%, transparent 70%);
}

.tru-home-purple-bg2 {
  background: radial-gradient(circle at 0% 0%, #652c80 0%, transparent 70%);
}

.tru-home-green-bg2 {
  background: radial-gradient(circle at 100% 0%, #38d58a 0%, transparent 30%);
}

.tru-home-purple-bg3 {
  background: radial-gradient(circle at -50% 20%, #652c80 0%, transparent 70%);
}

.tru-home-green-bg3 {
  background: radial-gradient(circle at 100% 20%, #38d58a 0%, transparent 50%);
}

.tru-home-blue-bg-video {
  background: radial-gradient(circle at 50% 40%, #68e0cf 0%, transparent 20%);
}

.tru-home-blue-bg2 {
  background: radial-gradient(circle at 0% 35%, #68e0cf 0%, transparent 20%);
}

.tru-home-purple-bg4 {
  background: radial-gradient(circle at 100% 55%, #652c80 0%, transparent 50%);
}

.tru-home-blue-bg-iphones {
  background: radial-gradient(circle at 50% 60%, #68e0cf 0%, transparent 30%);
}

.tru-home-green-bg4 {
  background: radial-gradient(circle at 0% 75%, #38d58a 0%, transparent 40%);
}

.tru-home-blue-bg3 {
  background: radial-gradient(circle at 100% 90%, #68e0cf 0%, transparent 20%);
}

.tru-home-purple-bg5 {
  background: radial-gradient(circle at 0% 100%, #652c80 0%, transparent 40%);
}

.tru-home-purple-bg6 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 0% 0%, #652c80 0%, transparent 50%);
  opacity: 0.3;
}

.tru-home-green-bg5 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 100% 70%, #38d58a 0%, transparent 40%);
  opacity: 0.2;
}

.tru-home-blue-bg4 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 0% 110%, #68e0cf 0%, transparent 40%);
  opacity: 0.3;
}

.tru-home-blue-bg5 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 0% 0%, #68e0cf 0%, transparent 50%);
  opacity: 0.3;
}

.tru-home-green-bg6 {
  z-index: -2;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 100% -40%, #38d58a 0%, transparent 40%);
  opacity: 0.2;
}

.tru-home-purple-bg7 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 100% 100%, #652c80 0%, transparent 50%);
  opacity: 0.3;
}

.tru-home-blue-bg6 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 100%, #68e0cf 0%, transparent 30%);
  opacity: 0.1;
}

/* Home bg gradients end */

.tru-hero-purple-light {
  background: radial-gradient(circle at 100% 50%, #652c80 0%, transparent 50%);
}

.tru-hero-blue-light {
  background: radial-gradient(circle at 0% 50%, #20568b 0%, transparent 50%);
}

.tru-redeem-background-blue {
  background: radial-gradient(
    circle at 50% 100%,
    #20568b 0%,
    #00203f 70%,
    transparent 100%
  );
}

.tru-redeem-background-green-purple {
  background: radial-gradient(
      circle at 0% 80%,
      #68e0cf 0%,
      #00203f 30%,
      transparent 100%
    ),
    radial-gradient(
      circle at 100% 60%,
      #652c80 0%,
      #00203f 50%,
      transparent 100%
    );
}
