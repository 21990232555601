.tru-delete-faq-answer p {
  color: #fff;
}

.tru-delete-faq-answer p:last-child {
  margin-bottom: 0;
}

.tru-delete-faq-answer li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.tru-delete-faq-answer li:last-child {
  margin-bottom: 1.25rem;
}

.tru-delete-faq-answer a {
  color: #38a6d5;
  text-decoration: underline;
}

.tru-delete-faq-answer a:hover {
  text-decoration: underline !important;
}

@media screen and (min-width: 768px) {
  .tru-delete-faq-answer li {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 992px) {
  .tru-delete-faq-answer a {
    text-decoration: none;
  }
  .tru-delete-faq-answer li {
    font-size: 1.25rem;
  }
}
