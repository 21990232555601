@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900");

* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  height: 100%;
}

body {
  color: #333;
  background-color: #00203f;
  font-family: Lato, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  min-height: 100%;
  padding: 0;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}

p {
  color: #626d7d;
  margin-bottom: 20px;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
}

button {
  border: none;
  cursor: pointer;
}

video {
  object-fit: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 0 0 0 0;
  vertical-align: baseline;
  display: inline-block;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input {
  line-height: normal;
  font-family: Lato, sans-serif;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/* Checkbox styling: https://dev.to/adbutterfield/custom-styling-checkboxes-the-modern-way-3o42 */
input[type="checkbox"] {
  box-sizing: border-box;
  width: 1.3rem;
  height: 1.3rem;
  padding: 0;
  border: 2px solid #a8bdd2;
  border-radius: 0.25rem;
  appearance: none;
  background-color: transparent;
  outline: none;
  transition: outline 0.1s;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-size: cover;
  padding: 2px;
}

input[type="checkbox"]:not(:disabled):checked {
  border: none;
  background-image: url("../assets/blue-checkmark.svg");
}

input[type="checkbox"].tru-checkbox-alt {
  border: 2px solid #fff;
}

textarea {
  overflow: auto;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

div[data-lastpass-icon-root] {
  display: none;
}

@media screen and (min-width: 480px) {
  h1 {
    font-size: 33px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 21px;
  }
  p {
    font-size: 17px;
  }
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 45px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 25px;
  }
  h5 {
    font-size: 20px;
  }
  p {
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 54px;
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 30px;
  }
  h5 {
    font-size: 25px;
  }
  h6 {
    font-size: 12px;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
}

/* Basic overridden styles */

/* Used for all Gradient headers/text */
.text-transparent {
  color: transparent;
}

/* Basic overridden styles end */

.tru-hero-mask-transition {
  mask-image: linear-gradient(
    180deg,
    #000 0%,
    #000 20%,
    #000 80%,
    rgba(0, 0, 0, 0) 100%
  );
}

.tru-menu-blur {
  backdrop-filter: blur(7.5px);
  transform: translate3d(0, 0, 0);
}

/* Group component shared */

/* Slider */

.tru-slide-in-anim {
  animation-name: slidein;
  animation-duration: 1s;
  transform: translateX(0px);
}

@keyframes slidein {
  0% {
    transform: translateX(calc(((100% + 5px) / 1) - 5px));
  }

  100% {
    transform: translateX(0px);
  }
}

.tru-slide-out-anim {
  animation-name: slideout;
  animation-duration: 1s;
  transform: translateX(0px);
}

@keyframes slideout {
  0% {
    transform: translateX(-800px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* Slider */
