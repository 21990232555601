.cookie-consent-container {
  padding: 1.5rem 3rem;
  background-color: white;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  border-radius: 10px;
  width: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 24px rgba(0, 27, 54, 0.5);
}

.close-button {
  position: absolute;
  top: 0.8rem;
  left: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
  max-width: 10px;
}

.cookie-consent-container button {
  margin-top: 1rem;
}

.cookie-consent-container .cookie-consent-inner-text a {
  color: #626d7d;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .cookie-consent-container {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .cookie-consent-container .cookie-consent-inner-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
  }

  .cookie-consent-container p {
    margin: 0 2rem 0.5rem 0;
    max-width: 80%;
    line-height: 1.4;
  }
}

.cookie-consent-container p {
  font-size: 1rem;
}

.cookie-consent-container button {
  padding: 0.5rem 3rem;
  margin: 1rem 0 1rem 0;
}
