.tru-ab-slide-in-anim {
  animation-name: bibleslidein;
  animation-duration: 1s;
  transform: translateX(0px);
}
.tru-ab-slide-out-anim {
  animation-name: bibleslideout;
  animation-duration: 1s;
  transform: translateX(0px);
}

@keyframes bibleslidein {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes bibleslideout {
  0% {
    transform: translateX(-250px);
  }
  100% {
    transform: translateX(0px);
  }
}
